import Login from '../pages/Login';
import Resigter from '../pages/Resigter';

//Public routes
export const publicRoutes = [
    { path: '/login', component: Login },
    { path: '/resigter', component: Resigter },
];

export const privateRoutes = [];
